import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocalhostRequestInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let newReq = req;

		if (newReq.url.includes('localhost')) {
			newReq = newReq.clone({
				headers: newReq.headers
					.set('x-IdIM', 'adriano_dedao')
					.set('Authorization', `Basic ${btoa('BSI50421:009365A@')}`)
					.set('apikey', 'l7c26961b663574dc488ac87484a222a4c'),
			});
		}

		return next.handle(newReq);
	}
}
