<div class="bx-uploads">
	<mat-accordion #accordionSocio="matAccordion" multi="true">
		<div
			class="bx-upload-doc bx-upload-box"
			*ngFor="let socio of socios; index as i"
		>
			<mat-expansion-panel>
				<mat-expansion-panel-header id="responsavel-header">
					<mat-panel-title>
						<div class="content-upload bx-subtitle">
							<p class="content-upload-responsavel-nome _mask">
								{{ socio.nome }}
							</p>
							<p
								class="content-upload-responsavel-tipo bx-text"
								*ngIf="!socio.administrador"
							>
								Sócio
							</p>
							<p
								class="content-upload-responsavel-tipo bx-text"
								*ngIf="socio.administrador"
							>
								Administrador
							</p>
						</div>
					</mat-panel-title>
					<mat-panel-description>
						<div class="material-icons">
							<mat-icon class="bs2-icon-success">
								{{
									socios[i].documentoPessoal.uploadsIds[0]
										? "check"
										: ""
								}}
							</mat-icon>
						</div>
						<p class="content-upload-descricao">
							Adicionar arquivos
						</p>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<div
					class="bx-upload-responsaveis"
					*ngIf="
						socios[i].documentoPessoal.tipo === TipoDocumentoEnum.RG
					"
				>
					<div class="bx-upload-add">
						<bs2-atualizacao-cadastral-box-adicionar-arquivo
							class="bx-adicionar-arquivos"
							[texto]="'Adicionar RG frente'"
							[icon]="'icon-capturarrg'"
							(click)="selecionarDocSocio($event, i)"
							*ngIf="!controleArquivos[i].frente.file"
						>
							<input
								type="file"
								class="file"
								style="display: none"
								(change)="
									enviarDocSocio(
										$event,
										i,
										socio.documento,
										'frente'
									)
								"
								accept="application/pdf, image/png, image/jpeg"
							/>
						</bs2-atualizacao-cadastral-box-adicionar-arquivo>

						<div
							*ngIf="controleArquivos[i].frente.loadArquivo"
							class="bx-contrato-ok bx-doc-option bx-remove-option"
						>
							<p class="content-upload-filename">
								{{
									controleArquivos[i]
										? controleArquivos[i].frente.file.name
										: ""
								}}
							</p>
							<div class="content-upload-progress">
								<bs2-atualizacao-cadastral-progresso-envio
									[velocidade]="'lento'"
									[conclusao]="isConcluido(i, 'frente')"
									[erro]="hasErro(i, 'frente')"
								>
								</bs2-atualizacao-cadastral-progresso-envio>
								<button
									class="icon icon-limpar remove-button"
									[disabled]="
										!isConcluido(i, 'frente') &&
										!hasErro(i, 'frente')
									"
									(click)="
										removerDocumento('socio', 'frente', i)
									"
								></button>
							</div>
						</div>
					</div>

					<div class="bx-upload-add">
						<bs2-atualizacao-cadastral-box-adicionar-arquivo
							class="bx-adicionar-arquivos"
							[texto]="'Adicionar RG verso'"
							[icon]="'icon-capturarrg'"
							(click)="selecionarDocSocio($event, i)"
							*ngIf="!controleArquivos[i].verso.file"
						>
							<input
								type="file"
								class="file"
								style="display: none"
								(change)="
									enviarDocSocio($event, i, socio.id, 'verso')
								"
								accept="application/pdf, image/png, image/jpeg"
							/>
						</bs2-atualizacao-cadastral-box-adicionar-arquivo>

						<div
							*ngIf="controleArquivos[i].verso.loadArquivo"
							class="bx-contrato-ok bx-doc-option bx-remove-option"
						>
							<p class="content-upload-filename">
								{{
									controleArquivos[i]
										? controleArquivos[i].verso.file.name
										: ""
								}}
							</p>
							<div class="content-upload-progress">
								<bs2-atualizacao-cadastral-progresso-envio
									[velocidade]="'lento'"
									[conclusao]="isConcluido(i, 'verso')"
									[erro]="hasErro(i, 'verso')"
								>
								</bs2-atualizacao-cadastral-progresso-envio>
								<button
									class="icon icon-limpar remove-button"
									[disabled]="
										!isConcluido(i, 'verso') &&
										!hasErro(i, 'verso')
									"
									(click)="
										removerDocumento('socio', 'verso', i)
									"
								></button>
							</div>
						</div>
					</div>
				</div>

				<div
					class="bx-upload-responsaveis"
					*ngIf="
						socios[i].documentoPessoal.tipo ===
						TipoDocumentoEnum.CNH
					"
				>
					<div class="bx-upload-add">
						<bs2-atualizacao-cadastral-box-adicionar-arquivo
							class="bx-adicionar-arquivos"
							[texto]="'Adicionar CNH aberta'"
							[icon]="'icon-capturacnh'"
							(click)="selecionarDocSocio($event, i)"
							*ngIf="!controleArquivos[i].frente.file"
						>
							<input
								type="file"
								class="file"
								style="display: none"
								(change)="
									enviarDocSocio(
										$event,
										i,
										socio.id,
										'frente'
									)
								"
								accept="application/pdf, image/png, image/jpeg"
							/>
						</bs2-atualizacao-cadastral-box-adicionar-arquivo>

						<div
							*ngIf="controleArquivos[i].frente.loadArquivo"
							class="bx-contrato-ok bx-doc-option bx-remove-option"
						>
							<p class="content-upload-filename">
								{{
									controleArquivos[i]
										? controleArquivos[i].frente.file.name
										: ""
								}}
							</p>
							<div class="content-upload-progress">
								<bs2-atualizacao-cadastral-progresso-envio
									[velocidade]="'lento'"
									[conclusao]="isConcluido(i, 'frente')"
									[erro]="hasErro(i, 'frente')"
								>
								</bs2-atualizacao-cadastral-progresso-envio>
								<button
									class="icon icon-limpar remove-button"
									[disabled]="
										!isConcluido(i, 'frente') &&
										!hasErro(i, 'frente')
									"
									(click)="
										removerDocumento('socio', 'frente', i)
									"
								></button>
							</div>
						</div>
					</div>
				</div>

				<div
					class="bx-upload-responsaveis"
					*ngIf="
						socios[i].documentoPessoal.tipo ===
						TipoDocumentoEnum.RNE
					"
				>
					<div class="bx-upload-add">
						<bs2-atualizacao-cadastral-box-adicionar-arquivo
							class="bx-adicionar-arquivos"
							[texto]="'Adicionar RNE frente'"
							[icon]="'icon-termosuso'"
							(click)="selecionarDocSocio($event, i)"
							*ngIf="!controleArquivos[i].frente.file"
						>
							<input
								type="file"
								class="file"
								style="display: none"
								(change)="
									enviarDocSocio(
										$event,
										i,
										socio.id,
										'frente'
									)
								"
								accept="application/pdf, image/png, image/jpeg"
							/>
						</bs2-atualizacao-cadastral-box-adicionar-arquivo>

						<div
							*ngIf="controleArquivos[i].frente.loadArquivo"
							class="bx-contrato-ok bx-doc-option bx-remove-option"
						>
							<p class="content-upload-filename">
								{{
									controleArquivos[i]
										? controleArquivos[i].frente.file.name
										: ""
								}}
							</p>
							<div class="content-upload-progress">
								<bs2-atualizacao-cadastral-progresso-envio
									[velocidade]="'lento'"
									[conclusao]="isConcluido(i, 'frente')"
									[erro]="hasErro(i, 'frente')"
								>
								</bs2-atualizacao-cadastral-progresso-envio>
								<button
									class="icon icon-limpar remove-button"
									[disabled]="
										!isConcluido(i, 'frente') &&
										!hasErro(i, 'frente')
									"
									(click)="
										removerDocumento('socio', 'frente', i)
									"
								></button>
							</div>
						</div>
					</div>

					<div class="bx-upload-add">
						<bs2-atualizacao-cadastral-box-adicionar-arquivo
							class="bx-adicionar-arquivos"
							[texto]="'Adicionar RNE verso'"
							[icon]="'icon-termosuso'"
							(click)="selecionarDocSocio($event, i)"
							*ngIf="!controleArquivos[i].verso.file"
						>
							<input
								type="file"
								class="file"
								style="display: none"
								(change)="
									enviarDocSocio($event, i, socio.id, 'verso')
								"
								accept="application/pdf, image/png, image/jpeg"
							/>
						</bs2-atualizacao-cadastral-box-adicionar-arquivo>

						<div
							*ngIf="controleArquivos[i].verso.loadArquivo"
							class="bx-contrato-ok bx-doc-option bx-remove-option"
						>
							<p class="content-upload-filename">
								{{
									controleArquivos[i]
										? controleArquivos[i].verso.file.name
										: ""
								}}
							</p>
							<div class="content-upload-progress">
								<bs2-atualizacao-cadastral-progresso-envio
									[velocidade]="'lento'"
									[conclusao]="isConcluido(i, 'verso')"
									[erro]="hasErro(i, 'verso')"
								>
								</bs2-atualizacao-cadastral-progresso-envio>
								<button
									class="icon icon-limpar remove-button"
									[disabled]="
										!isConcluido(i, 'verso') &&
										!hasErro(i, 'verso')
									"
									(click)="
										removerDocumento('socio', 'verso', i)
									"
								></button>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</div>
	</mat-accordion>
</div>
