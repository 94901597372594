import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

// traducao para o range de paginas na tabela
const customPaginatorRange = (page: number, pageSize: number, length: number) => {
	if (length === 0 || pageSize === 0) {
		return `0 de ${length}`;
	}

	length = Math.max(length, 0);

	const startIndex = page * pageSize;

	const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

	return `${startIndex + 1} - ${endIndex} de ${length}`;
};

@Injectable()
export class MatPaginatorTranslate extends MatPaginatorIntl {
	firstPageLabel = 'Primeira página';
	itemsPerPageLabel = 'Registros por página';
	lastPageLabel = 'Última página';
	nextPageLabel = 'Próxima página';
	previousPageLabel = 'Página anterior';
	getRangeLabel = customPaginatorRange;
}
