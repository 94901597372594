import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AtualizacaoModel } from './models/Atualizacao.model';
import { AtualizacaoDadosEmpresaModel, AtualizacaoDadosEmpresaSemAlteracaoModel } from './models/AtualizacaoDadosEmpresa.model';
import { AtualizarDadosSociosModel } from './models/AtualizarDadosSocios.model';
import { StatusModel } from './models/Status.model';
import { UploadModel } from './models/Upload.model';
import { Bs2AtualizacaoCadastralModalComponent } from '../../components/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral-modal/bs2-atualizacao-cadastral-modal.component';
import { Bs2AutenticacaoService, Bs2ModalService, Bs2RestClientService, EnvironmentService } from '@bs2/utilities-v9';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class Bs2AtualizacaoCadastralService {
	constructor(
		private environmentService: EnvironmentService,
		private autenticacaoService: Bs2AutenticacaoService,
		private bs2RestClientService: Bs2RestClientService,
		private modalService: Bs2ModalService
	) {}

	status(): Observable<StatusModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.get<StatusModel>(`${environment.empresasBaseURL}/empresas/atualizacao-cadastral/status`, {
			hideErrorToast: true,
			jweFull: true,
		});
	}

	updateSocios(dadosSocios: AtualizarDadosSociosModel): Observable<AtualizacaoModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.patch<AtualizacaoModel>(`${environment.empresasBaseURL}/empresas/atualizacao-cadastral/socios`, {
			body: dadosSocios,
			jweFull: true,
		});
	}

	updateEmpresa(dadosEmpresa: AtualizacaoDadosEmpresaModel | AtualizacaoDadosEmpresaSemAlteracaoModel): Observable<AtualizacaoModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.patch<AtualizacaoModel>(`${environment.empresasBaseURL}/empresas/atualizacao-cadastral`, {
			body: dadosEmpresa,
			jweFull: true,
		});
	}

	confirmar(atualizacaoId): Observable<any> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.patch<any>(`${environment.empresasBaseURL}/empresas/atualizacao-cadastral/conclusao`, {
			body: {
				atualizacaoId,
			},
			jweFull: true,
		});
	}

	uploadArquivo(arquivo, atualizacaoId) {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		const formData = new FormData();

		formData.append('arquivo', arquivo[0]);
		formData.append('atualizacaoId', atualizacaoId);

		return this.bs2RestClientService.post<UploadModel>(`${environment.empresasBaseURL}/empresas/atualizacao-cadastral/arquivos`, {
			body: formData,
		});
	}

	openModal(viewRef: ViewContainerRef, skip: boolean, status: StatusModel = null) {
		this.modalService.open(viewRef, {
			childComponent: Bs2AtualizacaoCadastralModalComponent,
			data: { status, skip },
			width: '600px',
		});
	}
}
