import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActiveFiltersBarComponent } from './components/active-filters-bar/active-filters-bar.component';
import { MaterialModule } from './material/material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { AccountCardComponent } from './components/account-card/account-card.component';
import { ValidateDateRangeDirective } from './directives/validate-date-range/validate-date-range.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { Bs2ComponentsModule, Bs2TwoFactorAuthenticationComponent, Bs2ModalTwoFactorAuthenticationComponent } from '@bs2/utilities-v9';
import { FormataContaPipe } from './pipes/formata-conta/formata-conta.pipe';
import { ContainerAuthenticationComponent } from './components/container-authentication/container-authentication.component';
import { MatInputAutoFocusDirective } from './directives/mat-input-auto-focus/mat-input-auto-focus.directive';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { FormataDocumentoPipe } from './pipes/formata-documento/formata-documento.pipe';
import { ContainerMiddleComponent } from './components/container-middle/container-middle.component';
import { ContainerBlankComponent } from './components/container-blank/container-blank.component';
import { ValidateDateRangeAllDirective } from './directives/validate-date-range-all/validate-date-range-all.directive';
import { SpecialCharacterDirective } from './directives/special-character/special-character.directive';
import { SaldoLimiteComponent } from './components/saldo-limite/saldo-limite.component';
import { SaldoContaComponent } from './components/saldo-conta/saldo-conta.component';
import { FormataDocumentoTributoPipe } from './pipes/formata-documento-tributo/formata-documento-tributo.pipe';
import { Bs2AutoPaginateComponent } from './components/bs2-auto-paginate/bs2-auto-paginate.component';
import { ModalNpsComponent } from './components/modal-nps/modal-nps.component';
import { Bs2AtualizacaoCadastralBaseComponent } from './components/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral-base/bs2-atualizacao-cadastral-base.component';
import { Bs2AtualizacaoCadastralBoxAdicionarArquivoComponent } from './components/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral-box-adicionar-arquivo/bs2-atualizacao-cadastral-box-adicionar-arquivo.component';
import { Bs2AtualizacaoCadastralBoxUploadComponent } from './components/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral-box-upload/bs2-atualizacao-cadastral-box-upload.component';
import { Bs2AtualizacaoCadastralModalComponent } from './components/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral-modal/bs2-atualizacao-cadastral-modal.component';
import { Bs2AtualizacaoCadastralProgressoEnvioComponent } from './components/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral-progresso-envio/bs2-atualizacao-cadastral-progresso-envio.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { Bs2StatusPointsComponent } from './components/bs2-status-points/bs2-status-points.component';
@NgModule({
	imports: [CommonModule, RouterModule, MaterialModule, ReactiveFormsModule, Bs2ComponentsModule, CurrencyMaskModule],
	declarations: [
		ActiveFiltersBarComponent,
		LoaderComponent,
		AccountCardComponent,
		ValidateDateRangeDirective,
		ValidateDateRangeAllDirective,
		FormataContaPipe,
		FormataContaPipe,
		ContainerAuthenticationComponent,
		ContainerBlankComponent,
		ContainerMiddleComponent,
		MatInputAutoFocusDirective,
		SpecialCharacterDirective,
		PhonePipe,
		FormataDocumentoPipe,
		SaldoLimiteComponent,
		SaldoContaComponent,
		FormataDocumentoTributoPipe,
		Bs2AutoPaginateComponent,
  		ModalNpsComponent,
		Bs2AtualizacaoCadastralBaseComponent,
		Bs2AtualizacaoCadastralBoxAdicionarArquivoComponent,
		Bs2AtualizacaoCadastralBoxUploadComponent,
		Bs2AtualizacaoCadastralModalComponent,
		Bs2AtualizacaoCadastralProgressoEnvioComponent,
     	Bs2StatusPointsComponent
	],
	exports: [
		ActiveFiltersBarComponent,
		LoaderComponent,
		AccountCardComponent,
		ValidateDateRangeDirective,
		ValidateDateRangeAllDirective,
		Bs2TwoFactorAuthenticationComponent,
		Bs2ModalTwoFactorAuthenticationComponent,
		FormataContaPipe,
		ContainerAuthenticationComponent,
		ContainerBlankComponent,
		ContainerMiddleComponent,
		FormataContaPipe,
		MatInputAutoFocusDirective,
		SpecialCharacterDirective,
		PhonePipe,
		FormataDocumentoPipe,
		FormataDocumentoTributoPipe,
		SaldoLimiteComponent,
		SaldoContaComponent,
		Bs2AutoPaginateComponent,
		Bs2AtualizacaoCadastralBaseComponent,
		Bs2AtualizacaoCadastralBoxAdicionarArquivoComponent,
		Bs2AtualizacaoCadastralBoxUploadComponent,
		Bs2AtualizacaoCadastralModalComponent,
		Bs2AtualizacaoCadastralProgressoEnvioComponent,
		Bs2StatusPointsComponent
	],
	providers: [],
})
export class SharedModule {}
