import { Component, ComponentRef, OnInit } from '@angular/core';
import { ModalModel, ModalOptionsModel } from '@bs2/utilities-v9';
import { StatusModel } from 'src/app/shared/services/bs2-atualizacao-cadastral/models/Status.model';

@Component({
	selector: 'bs2-atualizacao-cadastral-modal',
	templateUrl: './bs2-atualizacao-cadastral-modal.component.html',
	styleUrls: ['./bs2-atualizacao-cadastral-modal.component.scss'],
})
export class Bs2AtualizacaoCadastralModalComponent implements ModalModel {
	public options;

	ngOnInit(): void {}

	modalInit(reference: ComponentRef<ModalModel>, options: Partial<ModalOptionsModel<{ status: StatusModel; skip: boolean }, never>>) {
		this.options = options;
	}
}
