import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ParametrosModel } from './model/Parametros.model';
import { TimeModel } from './model/Time.model';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { Bs2EmpresaModel } from './model/Bs2Empresa.model';
import { Bs2AutenticacaoService, Bs2RestClientService, EnvironmentService } from '@bs2/utilities-v9';
import { environment } from 'src/environments/environment';
import { RetornoSessaoIdQrCode } from '@bs2/utilities-v9/lib/services/bs2-autenticacao/models/retornoSessaoIdQrCode.model';
import { RetornoStatusQrCode } from '@bs2/utilities-v9/lib/services/bs2-autenticacao/models/retornoStatusQrCode.model';

@Injectable({
	providedIn: 'root',
})
export class Bs2EmpresasService {
	private _parametros: ParametrosModel;
	private _time: Date;

	constructor(
		private environmentService: EnvironmentService,
		private autenticacaoService: Bs2AutenticacaoService,
		private restClientService: Bs2RestClientService
	) {}

	init() {
		if (!this.parametros) {
			this.getParametros();
			this.getTime();
		}
	}

	dadosEmpresa(): Observable<Bs2EmpresaModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.restClientService.get<Bs2EmpresaModel>(`${environment.empresasBaseURL}/empresas/atualizacao-cadastral`, {
			jweFull: true,
		});
	}

	getTermoMigracaoContaPagamentoPendente(): Observable<{
		pendente: boolean;
	}> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.restClientService.get<{
			pendente: boolean;
		}>(`${environment.empresasBaseURL}/empresas/${empresaId}/migracoes/contas/pendencia`, {
			hideErrorToast: true,
		});
	}

	private getParametros() {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.restClientService
			.get<ParametrosModel>(`${environment.empresasBaseURL}/empresas/${empresaId}/parametros`)
			.subscribe((data) => {
				this._parametros = data;
			});
	}

	private getTime() {
		return this.restClientService
			.get<TimeModel>(`${environment.empresasBaseURL}/time`, {
				hideErrorToast: true,
			})
			.pipe(finalize(() => this.initTimer()))
			.subscribe(
				(data) => {
					this._time = new Date(data.dataBr);
				},
				() => {
					this._time = new Date();
				}
			);
	}

	initTimer() {
		setInterval(() => {
			this._time = moment(this._time).add(10, 'second').toDate();
		}, 1000 * 10);
	}

	obterSessaoIdQrCode(mobileToken: string): Observable<RetornoSessaoIdQrCode> {
		return this.restClientService.post(`${environment.baseURLQrCode}/logins/qrcodes`, {
			body: { mobileToken: mobileToken },
			sendfingerPrintData: true,
			jweFull: true,
		});
	}

	iniciarSessaoQrCode(hash: string, qrCodeId: string) {
		return this.restClientService.patch(`${environment.baseURLQrCode}/logins/qrcodes/${qrCodeId}`, {
			body: { hash: hash },
			sendfingerPrintData: true,
			jweFull: true,
		});
	}

	consultaStatusQrCode(qrCodeId: string): Observable<RetornoStatusQrCode> {
		return this.restClientService.get(`${environment.baseURLQrCode}/logins/qrcodes/${qrCodeId}/status`, {
			sendfingerPrintData: true,
			hideErrorToast: true,
			jweFull: true,
		});
	}

	get parametros() {
		return this._parametros;
	}

	get actualTime() {
		return this._time;
	}
}
