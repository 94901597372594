import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import localePt from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
	Bs2ComponentsModule,
	GTAG_GROUP,
	PartnerInterceptor,
	TRANSLATE,
	LegalDocumentPipe,
	TelefonePipe,
	TranslateModel
} from '@bs2/utilities-v9';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { MaterialModule } from './shared/material/material.module';
import { NgxMaskModule } from 'ngx-mask';
import { LocalhostRequestInterceptor } from './core/interceptor/localhost-request.interceptor';
import { environment } from 'src/environments/environment';
import { bs2ApptoolsVersions } from './shared/constants/bs2-app-tools-versions.constant';

registerLocaleData(localePt);

const translate: TranslateModel = {
	defaltLang: 'pt-br',
	i18nPath: 'assets/i18n/',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatIconModule,
        AppRoutingModule,
        SharedModule,
        MaterialModule,
        HttpClientModule,
        Bs2ComponentsModule.forRoot({
            env: environment.env,
            appVersion: environment.version,
            produtoId: environment.produtoId,
			blipChatKey: environment.blipChatKey,
			partner: 'bs2',
			toolsVersions: bs2ApptoolsVersions,
			oatFactor: environment.oatFactor
        }),
        NgxMaskModule.forRoot(),
    ],
    providers: [
        { provide: GTAG_GROUP, useValue: 'wac_hmg' },
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: TRANSLATE, useValue: translate },
        // interceptors
        { provide: HTTP_INTERCEPTORS, useClass: PartnerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LocalhostRequestInterceptor, multi: true },
        DatePipe,
        LegalDocumentPipe,
        TelefonePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
