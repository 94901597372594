<div class="bs2-atualizacao-cadastral-base" [app-bs2-loader]="loader">
	<div class="step atualizacao-cadastral-aviso" *ngIf="step === StepsEnum.AvisoAtualizacao">
		<i class="icon-cadastrodecliente"></i>
		<h3>Atualização cadastral</h3>
		<br />
		<br />
		<p>
			Em breve, a atualização cadastral será<br />
			uma etapa obrigatória. Antecipe<br />
			a verificação dos seus dados.
		</p>
		<br />
		<br />
		<button mat-button (click)="iniciarValidacaoDados()">Verificar meus dados</button>
	</div>
	<div class="step atualizacao-cadastral-contador" *ngIf="step === StepsEnum.AvisoAtualizacaoContador">
		<i class="icon-cadastrodecliente"></i>
		<h3 *ngIf="!atrasado">Faltam</h3>
		<h3 *ngIf="atrasado && diasRestantes < diasRestantesPadrao">
			Sua atualização cadastral obrigatória<br />
			está atrasada há
		</h3>
		<h3 *ngIf="atrasado && diasRestantes >= diasRestantesPadrao">
			Sua atualização cadastral obrigatória<br />
			está atrasada há mais de
		</h3>
		<div class="contador">
			<div class="numero" *ngIf="diasRestantes < diasRestantesPadrao">
				<span>{{ getDiasRestantes.toString() | slice : 0 : 1 }}</span>
				<span>{{ getDiasRestantes.toString() | slice : 1 : 2 }}</span>
			</div>
			<div class="numero" *ngIf="diasRestantes >= diasRestantesPadrao">
				<span>9</span>
				<span>9</span>
			</div>
		</div>
		<h3>Dias</h3>
		<p *ngIf="atrasado">Efetue a atualização cadastral agora</p>
		<button mat-button (click)="iniciarValidacaoDados()">Verificar meus dados</button>
	</div>
	<div class="step step-form" *ngIf="step === StepsEnum.ConferirDadosEmpresa || step === StepsEnum.EditarDadosEmpresa">
		<h3>Confira seus dados cadastrais</h3>
		<p>
			Caso alguma informação esteja<br />
			desatualizada, selecione para alterar
		</p>
		<div class="title-form">
			<div class="count">1</div>
			Dados da empresa
		</div>
		<div class="form" [class.disabled]="step === StepsEnum.ConferirDadosEmpresa">
			<form [formGroup]="formEmpresa">
				<mat-form-field appearance="outline">
					<mat-label>CNPJ</mat-label>
					<input formControlName="cnpj" matInput />

					<mat-error error-message [control]="cnpj"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>E-mail do usuário master</mat-label>
					<input formControlName="email" matInput />

					<mat-error error-message [control]="email"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Telefone do usuário master</mat-label>
					<input formControlName="telefone" matInput />

					<mat-error error-message [control]="telefone"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Data de constituição</mat-label>
					<input formControlName="dataConstituicao" matInput />

					<mat-error error-message [control]="dataConstituicao"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Razão Social</mat-label>
					<input formControlName="razaoSocial" matInput maxlength="155" />

					<mat-error error-message [control]="razaoSocial"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Nome fantasia</mat-label>
					<input formControlName="nomeFantasia" matInput maxlength="155" />

					<mat-error error-message [control]="nomeFantasia"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Natureza Jurídica</mat-label>
					<mat-select [panelWidth]="null" formControlName="naturezaJuridica">
						<mat-option value="ME"> ME </mat-option>
						<mat-option value="LTDA"> LTDA </mat-option>
						<mat-option value="MEI"> MEI </mat-option>
						<mat-option value="EIRELI"> EIRELI </mat-option>
						<mat-option value="CONDOMINIO"> Condomínio </mat-option>
					</mat-select>

					<mat-error error-message [control]="naturezaJuridica"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Faturamento anual</mat-label>
					<input
						formControlName="faturamentoAnual"
						matInput
						placeholder="R$ 0,00"
						currencyMask
						type="tel"
						[options]="{ precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
					/>

					<mat-error error-message [control]="faturamentoAnual"></mat-error>
				</mat-form-field>
			</form>
		</div>
		<div class="row-btn" *ngIf="step === StepsEnum.ConferirDadosEmpresa">
			<button mat-button (click)="confirmarDadosEmpresa()" class="secundary">Confirmar dados</button>
			<button mat-button (click)="editarDadosEmpresa()">Editar dados</button>
		</div>
		<div class="row-btn" *ngIf="step === StepsEnum.EditarDadosEmpresa">
			<div></div>
			<button mat-button (click)="salvarDadosEmpresa()">Salvar dados</button>
		</div>
	</div>
	<div class="step step-form" *ngIf="step === StepsEnum.ConferirEnderecoEmpresa || step === StepsEnum.EditarEnderecoEmpresa">
		<h3>Confira seus dados cadastrais</h3>
		<p>
			Caso alguma informação esteja<br />
			desatualizada, selecione para alterar
		</p>
		<div class="title-form">
			<div class="count">2</div>
			Endereço da empresa
		</div>
		<div class="form" [class.disabled]="step === StepsEnum.ConferirEnderecoEmpresa">
			<form [formGroup]="formEmpresaEndereco">
				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>CEP</mat-label>
						<input formControlName="cep" matInput mask="00000-000" />

						<mat-error error-message [control]="cep"></mat-error>
					</mat-form-field>
					<div></div>
				</div>

				<mat-form-field appearance="outline">
					<mat-label>Endereço</mat-label>
					<input formControlName="endereco" matInput maxlength="100" />

					<mat-error error-message [control]="endereco"></mat-error>
				</mat-form-field>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Número</mat-label>
						<input formControlName="numero" matInput maxlength="8" />

						<mat-error error-message [control]="numero"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Complemento</mat-label>
						<input formControlName="complemento" matInput maxlength="50" />

						<mat-error error-message [control]="complemento"></mat-error>
					</mat-form-field>
				</div>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Bairro</mat-label>
						<input formControlName="bairro" matInput maxlength="50" />

						<mat-error error-message [control]="bairro"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Cidade</mat-label>
						<input formControlName="cidade" matInput maxlength="30" />

						<mat-error error-message [control]="cidade"></mat-error>
					</mat-form-field>
				</div>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Estado</mat-label>
						<input
							formControlName="estado"
							matInput
							maxlength="2"
							(keyup)="formEmpresaEndereco.patchValue({ estado: $event.target.value.toUpperCase() })"
						/>

						<mat-error error-message [control]="estado"></mat-error>
					</mat-form-field>
					<div></div>
				</div>
			</form>
		</div>
		<div class="row-btn" *ngIf="step === StepsEnum.ConferirEnderecoEmpresa">
			<button mat-button (click)="confirmarEnderecoEmpresa()" class="secundary">Confirmar dados</button>
			<button mat-button (click)="editarEnderecoEmpresa()">Editar dados</button>
		</div>
		<div class="row-btn" *ngIf="step === StepsEnum.EditarEnderecoEmpresa">
			<div></div>
			<button mat-button (click)="salvarEnderecoEmpresa()">Salvar dados</button>
		</div>
	</div>
	<div class="step atualizacao-cadastral-aviso" *ngIf="step === StepsEnum.AvisoDadosSocios">
		<i class="icon-shakehands"></i>
		<h3>
			Houve alteração no quadro<br />
			societário da empresa?
		</h3>
		<br />
		<br />
		<p>
			Caso haja alteração no quadro societário,<br />
			atualize as informações de cada um dos sócios
		</p>
		<br />
		<br />
		<div class="row-btn">
			<button mat-button (click)="naoHouveAlteracaoSocio()" class="secundary">Não houve</button>
			<button mat-button (click)="houveAlteracaoSocio()">Houve alteração</button>
		</div>
	</div>
	<div class="step lista-socio" *ngIf="step === StepsEnum.ListaConferirDadosSocios || step === StepsEnum.ListaEditarDadosSocios">
		<h3>Verifique o quadro societário</h3>
		<p>
			Atualize as informações dos sócios caso<br />
			haja alteração do quadro societário da empresa
		</p>

		<div class="progress-bar-socio">
			<span>{{ totalParticipacao }}% da participação societária</span>
			<mat-progress-bar mode="determinate" [value]="totalParticipacao"></mat-progress-bar>
		</div>

		<table mat-table [dataSource]="dataSourceSocios" class="mat-elevation-z8 tb-socios">
			<!-- Nome Column -->
			<ng-container matColumnDef="nome">
				<th mat-header-cell *matHeaderCellDef class="col-5">Nome do sócio</th>
				<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="col-nome _mask">
					{{ element.nome }}
				</td>
			</ng-container>

			<!-- Documento Column -->
			<ng-container matColumnDef="documento">
				<th mat-header-cell *matHeaderCellDef class="col-5">Documento do sócio</th>
				<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="col-documento _mask">
					{{ element.documento | legalDocument }}
				</td>
			</ng-container>

			<!-- Tipo Column -->
			<ng-container matColumnDef="tipo">
				<th class="col-4" mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element" class="col-tipo">
					{{ tipoSocio(element.tipo) }}
				</td>
			</ng-container>

			<!-- Participacao Column -->
			<ng-container matColumnDef="participacao">
				<th mat-header-cell *matHeaderCellDef class="col-3">Percentual Societário</th>
				<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="col-participacao _mask">
					<ng-container *ngIf="element.percentualSocietario"> {{ element.percentualSocietario }}% </ng-container>
				</td>
			</ng-container>

			<!-- Excluir Column -->
			<ng-container matColumnDef="actionExcluir">
				<th mat-header-cell *matHeaderCellDef class="col-1"></th>
				<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="col-actionExcluir _mask">
					<a class="excluir" (click)="excluirSocio(element)" *ngIf="step === StepsEnum.ListaEditarDadosSocios">
						<mat-icon>delete_forever</mat-icon>
					</a>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumnsSocios"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsSocios"></tr>
		</table>

		<button mat-button (click)="adicionarSocio()" *ngIf="step === StepsEnum.ListaEditarDadosSocios" class="secundary">
			Adicionar novo sócio ou administrador
		</button>

		<div class="row-btn" *ngIf="step === StepsEnum.ListaEditarDadosSocios">
			<div></div>
			<button mat-button (click)="finalizarEdicaoSocios()">Próximo</button>
		</div>

		<div class="row-btn" *ngIf="step === StepsEnum.ListaConferirDadosSocios">
			<button mat-button (click)="confirmarSocios()" class="secundary">Confirmar sócios</button>
			<button mat-button (click)="editarSocios()">Alterar sócios</button>
		</div>
	</div>
	<div class="step step-form" *ngIf="step === StepsEnum.FormSocio">
		<p class="left">Adicionar sócio</p>
		<br />
		<div class="title-form">
			<div class="count">1</div>
			Dados do sócio
		</div>
		<div class="form">
			<form [formGroup]="formSocio">
				<mat-form-field appearance="outline">
					<mat-label>CPF</mat-label>
					<input formControlName="documento" matInput [bs2CpfDirective]="documentoSocio" />

					<mat-error error-message [control]="documentoSocio"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Nome completo</mat-label>
					<input formControlName="nome" matInput maxlength="155" />

					<mat-error error-message [control]="nomeSocio"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>E-mail</mat-label>
					<input formControlName="email" matInput maxlength="50" />

					<mat-error error-message [control]="emailSocio"></mat-error>
				</mat-form-field>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Data de nascimento</mat-label>
						<input formControlName="dataNascimento" matInput [bs2DateMaskDirective]="dataNascimentoSocio" />

						<mat-error error-message [control]="dataNascimentoSocio"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Celular</mat-label>
						<input formControlName="celular" matInput [bs2PhoneMask]="celularSocio" />

						<mat-error error-message [control]="celularSocio"></mat-error>
					</mat-form-field>
				</div>

				<mat-form-field appearance="outline">
					<mat-label>Nome da mãe</mat-label>
					<input formControlName="nomeMae" matInput maxlength="100" />

					<mat-error error-message [control]="nomeMaeSocio"></mat-error>
				</mat-form-field>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Percentual societário</mat-label>
						<input formControlName="percentualSocietario" matInput currencyMask [options]="percentOptions" maxlength="7" />

						<mat-error error-message [control]="percentualSocietarioSocio"></mat-error>
					</mat-form-field>

					<mat-checkbox formControlName="administrador" (change)="administradorCheck($event)"> Administrador </mat-checkbox>
				</div>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Estado civil</mat-label>
						<mat-select [panelWidth]="null" formControlName="estadoCivil">
							<mat-option [value]="EstadoCivilEnum.SOLTEIRO"> Solteiro </mat-option>
							<mat-option [value]="EstadoCivilEnum.CASADO"> Casado </mat-option>
							<mat-option [value]="EstadoCivilEnum.DESQUITADO_SEPARADO"> Desquitado/Separado </mat-option>
							<mat-option [value]="EstadoCivilEnum.DIVORCIADO"> Divorciado </mat-option>
							<mat-option [value]="EstadoCivilEnum.VIUVO"> Viúvo </mat-option>
							<mat-option [value]="EstadoCivilEnum.UNIAO_ESTAVEL"> União estável </mat-option>
						</mat-select>

						<mat-error error-message [control]="estadoCivilSocio"></mat-error>
					</mat-form-field>
				</div>

				<ng-container
					*ngIf="estadoCivilSocio.value === EstadoCivilEnum.CASADO || estadoCivilSocio.value === EstadoCivilEnum.UNIAO_ESTAVEL"
				>
					<mat-form-field appearance="outline">
						<mat-label>Nome completo do cônjuge</mat-label>
						<input formControlName="conjugeNome" matInput maxlength="155" />

						<mat-error error-message [control]="conjugeNomeSocio"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>CPF do cônjuge</mat-label>
						<input formControlName="conjugeDocumento" matInput [bs2CpfDirective]="conjugeDocumentoSocio" />

						<mat-error error-message [control]="conjugeDocumentoSocio"></mat-error>
					</mat-form-field>

					<div class="row-form">
						<mat-form-field appearance="outline">
							<mat-label>E-mail do cônjuge</mat-label>
							<input formControlName="conjugeEmail" matInput maxlength="50" />

							<mat-error error-message [control]="conjugeEmailSocio"></mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Telefone do cônjuge</mat-label>
							<input formControlName="conjugeTelefone" matInput [bs2PhoneMask]="conjugeTelefoneSocio" />

							<mat-error error-message [control]="conjugeTelefoneSocio"></mat-error>
						</mat-form-field>
					</div>

					<div class="row-form">
						<mat-form-field appearance="outline">
							<mat-label>Regime de comunhão</mat-label>
							<mat-select [panelWidth]="null" formControlName="regimeComunhao">
								<mat-option [value]="RegimeComunhaoEnum.SeparacaoTotalDeBens"> Separação total de bens </mat-option>
								<mat-option [value]="RegimeComunhaoEnum.ParcialDeBens"> União parcial de bens </mat-option>
								<mat-option [value]="RegimeComunhaoEnum.TotalDeBens"> União total de bens </mat-option>
							</mat-select>

							<mat-error error-message [control]="regimeComunhaoSocio"></mat-error>
						</mat-form-field>
					</div>
				</ng-container>

				<div class="bx-checkbox">
					<mat-checkbox class="checkbox-pep" formControlName="socioPep" id="checkbox-pep">
						PEP (Pessoa Exposta Politicamente)
					</mat-checkbox>
					<i (click)="openModalPep()"> ?</i>
				</div>

				<br />
				<br />
				<div class="title-form">Documento pessoal</div>
				<div class="radio-documento">
					<mat-radio-group formControlName="tipoDocumento">
						<mat-radio-button [value]="TipoDocumentoEnum.CNH" class="primary"> CNH </mat-radio-button>
						<mat-radio-button [value]="TipoDocumentoEnum.RG" class="primary"> RG </mat-radio-button>
						<mat-radio-button [value]="TipoDocumentoEnum.RNE" class="primary"> RNE </mat-radio-button>
						<mat-error error-message [control]="tipoDocumentoSocio" *ngIf="tipoDocumentoSocio.touched"> </mat-error>
					</mat-radio-group>
				</div>

				<div *ngIf="tipoDocumentoSocio.value != null">
					<div class="row-form">
						<mat-form-field appearance="outline">
							<mat-label>Número de registro</mat-label>
							<input formControlName="numeroDocumento" matInput maxlength="50" />

							<mat-error error-message [control]="numeroDocumentoSocio"></mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Data de emissão</mat-label>
							<input formControlName="dataEmissaoDocumento" matInput mask="00/00/0000" />

							<mat-error error-message [control]="dataEmissaoDocumentoSocio"></mat-error>
						</mat-form-field>
					</div>

					<div class="row-form">
						<mat-form-field appearance="outline">
							<mat-label>UF expedidor</mat-label>
							<input
								formControlName="ufExpedidorDocumento"
								matInput
								(keyup)="formSocio.patchValue({ ufExpedidorDocumento: $event.target.value.toUpperCase() })"
								maxlength="2"
							/>

							<mat-error error-message [control]="ufExpedidorDocumentoSocio"></mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Orgão expedidor</mat-label>
							<input formControlName="orgaoExpedidorDocumento" matInput maxlength="10" />

							<mat-error error-message [control]="orgaoExpedidorDocumentoSocio"></mat-error>
						</mat-form-field>
					</div>
				</div>

				<div class="warning-documento">
					<i>!</i>
					Documento precisa estar dentro da validade.
				</div>

				<div class="row-btn">
					<button mat-button (click)="voltarSocio()" class="secundary">Voltar</button>
					<button mat-button (click)="submitSocio()">Próximo</button>
				</div>
			</form>
		</div>
	</div>
	<div class="step step-form" *ngIf="step === StepsEnum.FormSocioEndereco">
		<p class="left">Adicionar sócio</p>
		<br />
		<div class="title-form">
			<div class="count">2</div>
			Endereço do sócio
		</div>
		<div class="form">
			<form [formGroup]="formSocioEndereco">
				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>CEP</mat-label>
						<input formControlName="cep" matInput mask="00000-000" />

						<mat-error error-message [control]="cepSocio"></mat-error>
					</mat-form-field>
					<div></div>
				</div>

				<mat-form-field appearance="outline">
					<mat-label>Endereço</mat-label>
					<input formControlName="endereco" matInput maxlength="100" />

					<mat-error error-message [control]="enderecoSocio"></mat-error>
				</mat-form-field>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Número</mat-label>
						<input formControlName="numero" matInput maxlength="8" />

						<mat-error error-message [control]="numeroSocio"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Complemento</mat-label>
						<input formControlName="complemento" matInput maxlength="50" />

						<mat-error error-message [control]="complementoSocio"></mat-error>
					</mat-form-field>
				</div>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Bairro</mat-label>
						<input formControlName="bairro" matInput maxlength="50" />

						<mat-error error-message [control]="bairroSocio"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Cidade</mat-label>
						<input formControlName="cidade" matInput maxlength="30" />

						<mat-error error-message [control]="cidadeSocio"></mat-error>
					</mat-form-field>
				</div>

				<div class="row-form">
					<mat-form-field appearance="outline">
						<mat-label>Estado</mat-label>
						<input
							formControlName="estado"
							matInput
							maxlength="2"
							(keyup)="formSocioEndereco.patchValue({ estado: $event.target.value.toUpperCase() })"
						/>

						<mat-error error-message [control]="estadoSocio"></mat-error>
					</mat-form-field>
					<div></div>
				</div>
				<br />

				<div class="row-btn">
					<button mat-button (click)="voltarSocioEndereco()" class="secundary">Voltar</button>
					<button mat-button (click)="submitSocioEndereco()">Adicionar</button>
				</div>
			</form>
		</div>
	</div>
	<div class="step step-form" *ngIf="step === StepsEnum.UploadDocumentosSocios">
		<h3>
			Por favor, nos envie o<br />
			documento dos sócios
		</h3>
		<p class="blue">
			Envie frente e verso do RG ou da<br />CNH da pessoa abaixo.<br /><br />
			Formatos de arquivos aceitos: JPG, PNG ou PDF.<br />
			Tamanho máximo: 5MB.
		</p>

		<div class="warning-documento">
			<i>!</i>
			Não é permitido envio de fotocópia em preto e branco.
		</div>

		<bs2-atualizacao-cadastral-box-upload [atualizacaoId]="idAtualizacaoCadastral" (sociosChange)="uploadSocioChange($event)" [socios]="sociosParaAtualizar">
		</bs2-atualizacao-cadastral-box-upload>

		<div class="row-btn">
			<div></div>
			<button mat-button (click)="confirmarUploadSocios()">Enviar</button>
		</div>
	</div>
	<div class="step step-form" *ngIf="step === StepsEnum.UploadContratoSocial">
		<h3>
			Por favor, nos envie o<br />
			Contrato Social da sua empresa
		</h3>
		<br />
		<p class="blue">
			Formatos de arquivos aceitos: PDF, JPG, PNG.<br />
			Tamanho máximo: 5MB.
		</p>
		<h2 class="blue">Empresa {{ dadosEmpresa?.nomeFantasia || dadosEmpresa?.razaoSocial }}</h2>
		<br />

		<div class="bx-upload-add">
			<div *ngFor="let documento of documentoEmpresa; index as i" class="bx-contrato-ok bx-doc-option bx-remove-option">
				<p class="content-upload-filename">
					{{ documento.file.name || '' }}
				</p>
				<div class="content-upload-progress">
					<bs2-atualizacao-cadastral-progresso-envio
						[velocidade]="'lento'"
						[conclusao]="isConcluidoDocumentoEmpresa(i)"
						[erro]="hasErroDocumentoEmpresa(i)"
					>
					</bs2-atualizacao-cadastral-progresso-envio>
					<button
						class="icon icon-limpar remove-button"
						[disabled]="!isConcluidoDocumentoEmpresa(i) && !hasErroDocumentoEmpresa(i)"
						(click)="removerDocumentoEmpresa(i)"
					></button>
				</div>
			</div>

			<bs2-atualizacao-cadastral-box-adicionar-arquivo
				class="bx-adicionar-arquivos"
				[texto]="'Adicionar arquivo'"
				[icon]="'icon-termosuso'"
				(click)="selecionarDoc($event)"
			>
				<input
					type="file"
					class="file"
					style="display: none"
					(change)="enviarDoc($event)"
					accept="application/pdf, image/png, image/jpeg"
				/>
			</bs2-atualizacao-cadastral-box-adicionar-arquivo>
		</div>

		<div class="row-btn">
			<div></div>
			<button mat-button (click)="confirmarUpload()">Enviar</button>
		</div>
	</div>
	<div class="step atualizacao-cadastral-aviso" *ngIf="step === StepsEnum.ConfirmacaoDados">
		<i class="icon-termosuso"></i>
		<h3>Para concluir precisamos da sua confirmação.</h3>
		<br />
		<br />
		<mat-checkbox (change)="confirmacaoCadastroChange($event)" class="checkboxConfirmacao">
			<span
				>Declaro que todos os dados informados<br />
				são verdadeiros e estão atualizados.</span
			>
		</mat-checkbox>
		<button mat-button (click)="confirmarAtualizacao()">Confirmar</button>
	</div>
	<div class="step atualizacao-cadastral-aviso" *ngIf="step === StepsEnum.Sucesso">
		<i class="icon-like01"></i>
		<h3>
			Obrigado pelo envio das informações! Sua atualização cadastral está em análise. Assim que concluirmos, te avisaremos por e-mail.
		</h3>
		<br />
		<br />
		<p>Aguarde nosso próximo contato!</p>
		<br />
		<br />
		<button mat-button (click)="modalService.close()">Voltar</button>
	</div>
</div>
