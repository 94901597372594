export const environment = {
	production: false,
	env: 'hml',
	local: false,
	timer: 10,
	version: '16.0.$(Release.ReleaseId)',
	produtoId: '53986e60-6fe7-4dd9-bc8e-49f3cadb62db',
	oatFactor: 'teste',
	
	// business api
	baseURL: 'https://apihmz.bancobonsucesso.com.br/pj/banking/forfrontend/spa/v1',
	empresasBaseURL: 'https://apihmz.bancobonsucesso.com.br/pj/empresas/forfrontend/spa/v1',
    contextUrl: `https://apihmz.bancobonsucesso.com.br/pj/empresas/forfrontend/spa/v1/empresas/contexto`,
	basePixURL: 'https://apihmz.bancobonsucesso.com.br/pj/pix/forfrontend/spa/v1',
	baseURLQrCode: 'https://apihmz.bancobonsucesso.com.br/pj/empresas/qrcodes/forfrontend/spa/v1',
	
	recaptchaSiteKey: '6LdIRMAUAAAAAP7FobM9cEnR5zYHP6_eJpL_IC-c',

	blipChatKey: 'aG1sYnMyZW1wcmVzYXM6ODNmMWQ4YTYtMjZkNC00YzFmLWI0ZjEtOGUyYTBiOGM3OWEz'
};
