import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Bs2AutenticacaoService, Bs2GTAGServiceService, Bs2PageProgressBarService, WhiteLabelService } from '@bs2/utilities-v9';
import { environment } from 'src/environments/environment';

declare const gtag;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	// Sets initial value to true to show loading spinner on first load
	loading = true;

	// tslint:disable-next-line:max-line-length
	constructor(
		public whiteLabel: WhiteLabelService,
		public renderer: Renderer2,
		private pageProgressBarService: Bs2PageProgressBarService,
		private router: Router,
		private autenticacaoService: Bs2AutenticacaoService,
		private gtagService: Bs2GTAGServiceService
	) {
		this.whiteLabel.renderer = renderer;
		// tslint:disable-next-line: no-unused-expression
		this.router.events.subscribe((event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.pageProgressBarService.show();

					if (typeof gtag !== 'undefined') {
						gtag('config', ' UA-182899776-1 ', { groups: 'wac_hmg' });
						gtag('config', ' G-6G4GEHSHSQ ', { groups: 'wac_hmg' });
					}

					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.pageProgressBarService.hide();
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	ngOnInit() {

		const documento =
			this.autenticacaoService.empresas &&
			this.autenticacaoService.empresas.length > 0 &&
			this.autenticacaoService.activeEmpresa.documento;

		if (documento) {
			this.gtagService.setHashUserID(documento);
		}
	}
}
