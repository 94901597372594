import { Component, Input } from '@angular/core';

@Component({
	selector: 'bs2-atualizacao-cadastral-box-adicionar-arquivo',
	templateUrl: './bs2-atualizacao-cadastral-box-adicionar-arquivo.component.html',
	styleUrls: ['./bs2-atualizacao-cadastral-box-adicionar-arquivo.component.scss'],
})
export class Bs2AtualizacaoCadastralBoxAdicionarArquivoComponent {
	@Input() texto: string;
	@Input() icon: string;

	constructor() {}
}
