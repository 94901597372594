import { Component, ComponentRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment/moment';
import { debounceTime, filter, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SocioModel } from '../../../services/bs2-empresas/model/Socio.model';
import { Bs2EmpresaModel } from '../../../services/bs2-empresas/model/Bs2Empresa.model';
import { SocioParaAlterarModel } from '../../../services/bs2-atualizacao-cadastral/models/SocioParaAlterar.model';
import { AtualizarDadosSociosModel } from '../../../services/bs2-atualizacao-cadastral/models/AtualizarDadosSocios.model';
import { Bs2AtualizacaoCadastralService } from '../../../services/bs2-atualizacao-cadastral/bs2-atualizacao-cadastral.service';
import { Bs2EmpresasService } from '../../../services/bs2-empresas/bs2-empresas.service';
import { TipoDocumentoEnum } from '../../../services/bs2-atualizacao-cadastral/enums/TipoDocumento.enum';
import { EstadoCivilEnum } from '../../../services/bs2-empresas/enums/EstadoCivil.enum';
import { TipoSocioEnum } from '../../../services/bs2-empresas/enums/TipoSocio.enum';
import { RegimeComunhaoEnum } from '../../../services/bs2-empresas/enums/RegimeComunhao.enum';
import { tiposPermitidos } from '../../../services/bs2-atualizacao-cadastral/helpers/TiposPermitidos';
import { Bs2AutenticacaoService, Bs2LoginService, Bs2ModalAlertComponent, Bs2ModalInformacaoComponent, Bs2ModalService, Bs2ToastService, Bs2UtilitiesService, LegalDocumentPipe, TelefonePipe, ToastTipo, bs2Validators } from '@bs2/utilities-v9';

enum StepsEnum {
	AvisoAtualizacao = 1,
	AvisoAtualizacaoContador = 2,
	ConferirDadosEmpresa = 3,
	EditarDadosEmpresa = 4,
	ConferirEnderecoEmpresa = 5,
	EditarEnderecoEmpresa = 6,
	AvisoDadosSocios = 7,
	ListaConferirDadosSocios = 8,
	ListaEditarDadosSocios = 9,
	FormSocio = 10,
	FormSocioEndereco = 11,
	UploadDocumentosSocios = 12,
	UploadContratoSocial = 13,
	ConfirmacaoDados = 14,
	Sucesso = 15,
}

@Component({
	selector: 'bs2-atualizacao-cadastral-base',
	templateUrl: './bs2-atualizacao-cadastral-base.component.html',
	styleUrls: ['./bs2-atualizacao-cadastral-base.component.scss'],
})
export class Bs2AtualizacaoCadastralBaseComponent implements OnInit {
	@Input() options;
	maxFileSize: number = 5242880;
	loader: boolean;
	displayedColumnsSocios: string[] = ['nome', 'documento', 'tipo', 'participacao', 'actionExcluir'];
	dataSourceSocios = new MatTableDataSource<SocioModel>();
	idAtualizacaoCadastral: string;

	empresaFoiAlterada: boolean;
	listaDeSociosFoiAlterada: boolean;

	step: StepsEnum;

	obrigatorio: boolean;

	dadosEmpresa: Bs2EmpresaModel;
	documentoEmpresa: {
		loadArquivo: boolean;
		loadArquivoConclusao: boolean;
		file: any;
		uploadId: string;
		erro: boolean;
	}[] = [];

	formEmpresa: UntypedFormGroup;
	formEmpresaEndereco: UntypedFormGroup;
	formSocio: UntypedFormGroup;
	formSocioEndereco: UntypedFormGroup;

	socioAdministrador: boolean;
	documentoPessoalSocioTipo: string;

	diasRestantes: number;

	atrasado: boolean;

	diasRestantesPadrao: number = 100;
	sociosParaAtualizar: SocioParaAlterarModel[] = [];

	atualizarDadosSociosFinal: AtualizarDadosSociosModel = {
		atualizacaoId: null,
		uploadsIds: [],
	};

	confirmacaoAtualizacao: boolean;

	percentOptions = {
		precision: 2,
		allowNegative: false,
		prefix: '',
		suffix: '%',
		decimal: '.',
		align: 'left',
	};

	constructor(
		private fb: UntypedFormBuilder,
		private autenticacaoService: Bs2AutenticacaoService,
		private atualizacaoCadastralService: Bs2AtualizacaoCadastralService,
		private empresaService: Bs2EmpresasService,
		private modalService: Bs2ModalService,
		private documentPipe: LegalDocumentPipe,
		private telefonePipe: TelefonePipe,
		private viewRef: ViewContainerRef,
		private toastService: Bs2ToastService,
		private utilsService: Bs2UtilitiesService,
		public bs2LoginService: Bs2LoginService
	) {}

	ngOnInit() {
		const { status, skip } = this.options.data || {};

		if (status) {
			this.obrigatorio = status.obrigatorio;
			this.atrasado = status.diasRestantes < 0;
			const diasRestantes = Math.abs(status.diasRestantes);

			this.diasRestantes = Number(diasRestantes.toString().padStart(2, '0'));
		}

		// Inicializa forms de socios
		this.formSocio = this.fb.group({
			documento: ['', [Validators.required, bs2Validators.cpf]],
			nome: [
				'',
				[
					Validators.required,
					bs2Validators.fullName,
					bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos'),
				],
			],
			email: ['', [Validators.required, bs2Validators.email]],
			dataNascimento: [
				'',
				[Validators.required, bs2Validators.validDate, bs2Validators.lessThanHundredYears, bs2Validators.notFutureDate],
			],
			celular: ['', [Validators.required, bs2Validators.cellphone]],
			nomeMae: [
				'',
				[
					Validators.required,
					bs2Validators.fullName,
					bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos'),
				],
			],
			percentualSocietario: [null, []],
			administrador: [false, []],
			socioPep: [false, []],
			tipoDocumento: [null, [Validators.required]],
			numeroDocumento: ['', [Validators.required]],
			dataEmissaoDocumento: ['', [Validators.required, bs2Validators.validDate, bs2Validators.notFutureDate]],
			ufExpedidorDocumento: ['', [Validators.required, bs2Validators.brazilianStates]],
			orgaoExpedidorDocumento: [
				'',
				[Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			estadoCivil: ['', [Validators.required]],
			regimeComunhao: ['', []],
			conjugeNome: ['', []],
			conjugeDocumento: ['', []],
			conjugeEmail: ['', []],
			conjugeTelefone: ['', []],
		});

		this.formSocioEndereco = this.fb.group({
			endereco: ['', [Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')]],
			bairro: ['', [Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')]],
			cep: ['', [Validators.required, bs2Validators.cep]],
			numero: ['', [Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')]],
			complemento: ['', [bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')]],
			cidade: ['', [Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')]],
			estado: ['', [Validators.required, bs2Validators.brazilianStates]],
		});

		// Busca de CEP
		this.cepSocio.valueChanges
			.pipe(
				filter((cep) => cep && cep.length === 8),
				debounceTime(300)
			)
			.subscribe((cep) => {
				this.utilsService.getEnderecoByCep(cep, true, true).subscribe((endereco) => {
					this.enderecoSocio.setValue(endereco.logradouro);
					this.bairroSocio.setValue(endereco.bairro);
					this.cidadeSocio.setValue(endereco.localidade);
					this.complementoSocio.setValue(endereco.complemento);
					this.estadoSocio.setValue(endereco.uf);
				});
			});

		this.tipoDocumentoSocio.valueChanges.subscribe((data) => {
			this.numeroDocumentoSocio.setValue(null);
			if (data === TipoDocumentoEnum.CNH) {
				this.numeroDocumentoSocio.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
			} else if (data === TipoDocumentoEnum.RG) {
				this.numeroDocumentoSocio.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(11)]);
			} else if (data === TipoDocumentoEnum.RNE) {
				this.numeroDocumentoSocio.setValidators([Validators.required, Validators.minLength(3)]);
			}
			this.numeroDocumentoSocio.updateValueAndValidity();
		});

		this.estadoCivilSocio.valueChanges.subscribe((data) => {
			if (data === EstadoCivilEnum.CASADO || data === EstadoCivilEnum.UNIAO_ESTAVEL) {
				this.regimeComunhaoSocio.setValidators([Validators.required]);
				this.conjugeNomeSocio.setValidators([Validators.required, bs2Validators.fullName]);
				this.conjugeDocumentoSocio.setValidators([Validators.required, bs2Validators.cpf]);
				this.conjugeEmailSocio.setValidators([Validators.required, bs2Validators.email]);
				this.conjugeTelefoneSocio.setValidators([Validators.required, bs2Validators.phone]);
			} else {
				this.regimeComunhaoSocio.setValidators([]);
				this.conjugeNomeSocio.setValidators([]);
				this.conjugeDocumentoSocio.setValidators([]);
				this.conjugeEmailSocio.setValidators([]);
				this.conjugeTelefoneSocio.setValidators([]);

				this.regimeComunhaoSocio.setValue(null);
				this.conjugeNomeSocio.setValue(null);
				this.conjugeDocumentoSocio.setValue(null);
				this.conjugeEmailSocio.setValue(null);
				this.conjugeTelefoneSocio.setValue(null);
			}

			this.regimeComunhaoSocio.updateValueAndValidity();
			this.conjugeNomeSocio.updateValueAndValidity();
			this.conjugeDocumentoSocio.updateValueAndValidity();
			this.conjugeEmailSocio.updateValueAndValidity();
			this.conjugeTelefoneSocio.updateValueAndValidity();
		});

		if (!skip) {
			if (status.diasRestantes >= 0) {
				this.step = StepsEnum.AvisoAtualizacao;
			} else {
				this.step = StepsEnum.AvisoAtualizacaoContador;
			}
		} else {
			this.iniciarValidacaoDados();
		}
	}

	prepareFormsEmpresa() {
		// Inicia forms empresa com dados recebidos
		this.formEmpresa = this.fb.group({
			cnpj: [{ value: this.documentPipe.transform(this.dadosEmpresa.documento), disabled: true }, []],
			email: [{ value: this.dadosEmpresa.email, disabled: true }, []],
			telefone: [{ value: this.telefonePipe.transform(this.dadosEmpresa.telefone), disabled: true }, []],
			dataConstituicao: [{ value: moment(this.dadosEmpresa.dataConstituicao).format('DD/MM/YYYY'), disabled: true }, []],
			razaoSocial: [
				this.dadosEmpresa.razaoSocial,
				[Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			nomeFantasia: [
				this.dadosEmpresa.nomeFantasia,
				[bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			naturezaJuridica: [this.dadosEmpresa.naturezaJuridica, [Validators.required]],
			faturamentoAnual: [this.dadosEmpresa.faturamentoAnual || 0, [Validators.required, Validators.min(1)]],
		});

		this.formEmpresaEndereco = this.fb.group({
			endereco: [
				this.dadosEmpresa.enderecos[0].logradouro,
				[Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			bairro: [
				this.dadosEmpresa.enderecos[0].bairro,
				[Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			cep: [this.dadosEmpresa.enderecos[0].cep, [Validators.required, bs2Validators.cep]],
			numero: [this.dadosEmpresa.enderecos[0].numero, [Validators.required]],
			complemento: [
				this.dadosEmpresa.enderecos[0].complemento,
				[bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			cidade: [
				this.dadosEmpresa.enderecos[0].cidade,
				[Validators.required, bs2Validators.pattern(/^[a-zA-Z0-9 à-úÀ-Ú-_(&-)'.]*$/, 'Caracteres inválidos')],
			],
			estado: [this.dadosEmpresa.enderecos[0].uf, [Validators.required, bs2Validators.brazilianStates]],
		});

		// Busca de CEP
		this.cep.valueChanges
			.pipe(
				filter((cep) => cep.length === 8),
				debounceTime(300)
			)
			.subscribe((cep) => {
				this.utilsService.getEnderecoByCep(cep, true, true).subscribe((endereco) => {
					this.endereco.setValue(endereco.logradouro);
					this.bairro.setValue(endereco.bairro);
					this.cidade.setValue(endereco.localidade);
					this.complemento.setValue(endereco.complemento);
					this.estado.setValue(endereco.uf);
				});
			});
	}

	iniciarValidacaoDados() {
		this.loader = true;

		// Busca dados da empresa quando o usuário opta por atualizar seus dados cadastrais
		this.empresaService.dadosEmpresa().subscribe(
			(dados) => {
				this.dadosEmpresa = dados;
				this.prepareFormsEmpresa();
				this.step = dados.faturamentoAnual > 0 ? StepsEnum.ConferirDadosEmpresa : StepsEnum.EditarDadosEmpresa;
				this.loader = false;
				this.dataSourceSocios.data = dados.socios;
			},
			() => {
				this.modalService.close();
			}
		);
	}

	// Step Dados Empresa
	confirmarDadosEmpresa() {
		// Usuário confirmou os dados sem alteração
		this.step = StepsEnum.ConferirEnderecoEmpresa;
	}

	editarDadosEmpresa() {
		// Usuário optou por atualizar dados, isso exibe o formulário para edição
		this.step = StepsEnum.EditarDadosEmpresa;
	}

	salvarDadosEmpresa() {
		// Usuario alterou dados da empresa e confirmou
		Object.keys(this.formEmpresa.controls).forEach((field) => {
			// {1}
			const control = this.formEmpresa.get(field); // {2}
			control.markAsTouched({ onlySelf: true }); // {3}
		});

		if (this.formEmpresa.valid) {
			this.empresaFoiAlterada = true;
			this.step = StepsEnum.ConferirEnderecoEmpresa;
		}
	}

	// Step Endereco Empresa
	confirmarEnderecoEmpresa() {
		if (this.empresaFoiAlterada) {
			// Usuario optou por não alterar dados de endereço mas alterou dados da empresa, por isso é necessário fazer o patch da empresa
			this.salvarEnderecoEmpresa();
		} else {
			// Usuario optou por não alterar nada da empresa, mas será feito um patch informando que não houve alteração, assim retornando o id de atualização para prosseguir com os patchs futuros
			this.loader = true;
			this.atualizacaoCadastralService
				.updateEmpresa({
					semAlteracao: true,
				})
				.pipe(finalize(() => (this.loader = false)))
				.subscribe((atualizacao) => {
					this.idAtualizacaoCadastral = atualizacao.atualizacaoId;
					this.step = StepsEnum.AvisoDadosSocios;
				});
		}
	}

	editarEnderecoEmpresa() {
		// Usuário optou por atualizar dados, isso exibe o formulário para edição
		this.step = StepsEnum.EditarEnderecoEmpresa;
	}

	salvarEnderecoEmpresa() {
		// Soliciação de patch dos dados da empresa
		Object.keys(this.formEmpresaEndereco.controls).forEach((field) => {
			const control = this.formEmpresaEndereco.get(field);
			control.markAsTouched({ onlySelf: true });
		});

		if (this.formEmpresaEndereco.valid) {
			this.loader = true;
			this.atualizacaoCadastralService
				.updateEmpresa({
					nomeFantasia: this.nomeFantasia.value,
					faturamento: this.faturamentoAnual.value,
					naturezaJuridica: this.naturezaJuridica.value,
					razaoSocial: this.razaoSocial.value,
					endereco: {
						bairro: this.bairro.value,
						cep: this.cep.value.replace(/\D/g, ''),
						cidade: this.cidade.value,
						complemento: this.complemento.value,
						logradouro: this.endereco.value,
						numero: this.numero.value,
						uf: this.estado.value,
					},
				})
				.pipe(finalize(() => (this.loader = false)))
				.subscribe((atualizacao) => {
					this.idAtualizacaoCadastral = atualizacao.atualizacaoId;

					// Empresa é marcada como alterada, isto garante que o usuario passará pelo processo de upload de contrato social
					this.empresaFoiAlterada = true;
					this.step = StepsEnum.AvisoDadosSocios;
				});
		}
	}

	// Step dados socios
	// Socios
	naoHouveAlteracaoSocio() {
		// Usuario confirma que não houve alteração dos sócios
		if (this.empresaFoiAlterada) {
			// Porém se foi alterada a empresa em algum step anterior ele será encaminhado para o step de upload do contrato social
			this.step = StepsEnum.UploadContratoSocial;
		} else {
			// Não houve alteração de empresa nem de sócios, o usuário será encaminhado direto para confirmação da atualização cadastral
			this.step = StepsEnum.ConfirmacaoDados;
		}
	}

	houveAlteracaoSocio() {
		// Usuario optou que houve alteração e será encaminhado para listagem de socios
		this.step = StepsEnum.ListaConferirDadosSocios;
	}

	confirmarSocios() {
		if (this.totalParticipacao < 0.1)
			return this.toastService.open('O total da participação societária precisa ser no mínimo 0.1%', ToastTipo.Erro, 5000);
		// O usuário confirmou a lista de sócios atuais, não houve alteração na lista
		if (this.empresaFoiAlterada) {
			// Se a empresa foi alterada o usuario irá para o step de upload do contrato social
			this.step = StepsEnum.UploadContratoSocial;
		} else {
			// Se não, não houve nenhuma alteração então o usuario poderá ir para confirmação
			this.step = StepsEnum.ConfirmacaoDados;
		}
	}

	openModalPep() {
		this.modalService.open(this.viewRef, {
			childComponent: Bs2ModalInformacaoComponent,
			data: {
				title: 'O que é PEP?',
				message: `Agentes públicos que desempenham ou tenham desempenhado, <br>
                nos cinco anos anteriores, no Brasil ou em países, territórios e de- <br>
                pendências estrangeiras, cargos, empregos ou funções públicas relevantes, assim como seus representantes, familiares e estreitos colaboradores.`,
				buttonText: 'Entendi',
				largeButton: true,
			},
		});
	}

	editarSocios() {
		// O usuario optou pela edição de sócios, nesse cenário todos os sócios são apagados da lista e ele deve cadastrar todos novamente, caso confirme
		const editarSocioSubject = new Subject<boolean>();

		this.modalService.open(this.viewRef, {
			childComponent: Bs2ModalAlertComponent,
			data: {
				title: 'Atenção',
				description:
					'Ao editar os dados societários, será necessário cadastrar novamente os dados de todos os sócios e administradores',
				buttons: [
					{
						text: 'Cancelar',
						value: false,
					},
					{
						text: 'Ok, Entendi!',
						primary: true,
						value: true,
					},
				],
			},
			closeSubject: editarSocioSubject,
			width: '500px',
		});

		editarSocioSubject.subscribe((confirmado) => {
			this.modalService.close();
			if (confirmado) {
				this.dataSourceSocios.data = [];
				this.listaDeSociosFoiAlterada = true;
				this.step = StepsEnum.ListaEditarDadosSocios;
			}
		});
	}

	excluirSocio(socio: SocioModel) {
		// Remoção de sócio da lista
		this.sociosParaAtualizar = this.sociosParaAtualizar.filter(
			(s) => s.documento.replace(/\D/g, '') !== socio.documento.replace(/\D/g, '')
		);
		this.dataSourceSocios.data = this.dataSourceSocios.data.filter(
			(s) => s.documento.replace(/\D/g, '') !== socio.documento.replace(/\D/g, '')
		);
	}

	adicionarSocio() {
		// O usuário optou por criar um sócio e irá para o step do form de sócio
		this.step = StepsEnum.FormSocio;

		setTimeout(() => {
			this.formSocio.get('percentualSocietario').setValue('');
			this.formSocio.get('percentualSocietario').setValidators([]);

			this.formSocio.get('percentualSocietario').setValidators([Validators.required, Validators.min(0.1), Validators.max(100)]);

			this.formSocio.get('percentualSocietario').updateValueAndValidity();
		}, 100);
	}

	voltarSocio() {
		this.step = StepsEnum.ListaEditarDadosSocios;
	}

	voltarSocioEndereco() {
		this.step = StepsEnum.FormSocio;

		setTimeout(() => {
			if (this.administradorSocio.value) {
				this.formSocio.get('percentualSocietario').setValue('');
				this.formSocio.get('percentualSocietario').setValidators([]);
			} else {
				this.formSocio.get('percentualSocietario').setValidators([Validators.required, Validators.min(0.1), Validators.max(100)]);
			}

			this.formSocio.get('percentualSocietario').updateValueAndValidity();
		}, 100);
	}

	submitSocio() {
		// O usuário submeteu o primeiro step do cadastro de novo sócio
		this.tipoDocumentoSocio.markAsTouched();
		if (this.formSocio.valid) {
			const hasSocio = !!this.sociosParaAtualizar.find((s) => s.documento === this.documentoSocio.value.replace(/\D/g, ''));
			const hasAdministrador = !!this.sociosParaAtualizar.find((s) => s.administrador);
			const totalPercentual = this.sociosParaAtualizar.reduce((total, actual) => total + actual.percentualSocietario, 0);

			if (!this.administradorSocio.value) {
				if (totalPercentual + parseFloat(this.percentualSocietarioSocio.value) > 100) {
					this.toastService.open('A soma do percentual societário ultrapassa 100%', ToastTipo.Erro, 5000);
					return;
				}
			} else {
				if (hasAdministrador) {
					this.toastService.open('Já existe um administrador cadastrado', ToastTipo.Erro, 5000);
					return;
				}
			}

			if (!hasSocio) {
				this.step = StepsEnum.FormSocioEndereco;
			} else {
				this.toastService.open('Sócio já cadastrado', ToastTipo.Erro, 5000);
			}
		}
	}

	submitSocioEndereco() {
		// Usuário submeteu o form de endereço do novo sócio, nesse momento o sócio é adicionado na lista, mas não é enviado para a API, isso é feito de uma vez na confirmação do step de contrato social

		if (this.formSocioEndereco.valid) {
			this.dataSourceSocios.data = [
				...this.dataSourceSocios.data,
				{
					nome: this.nomeSocio.value,
					documento: this.documentoSocio.value,
					percentualSocietario: !this.administradorSocio.value ? parseFloat(this.percentualSocietarioSocio.value) : null,
					tipo: this.administradorSocio.value ? TipoSocioEnum.RepresentanteLegal : TipoSocioEnum.Socio,
					estadoCivil: this.estadoCivilSocio.value,
					regimeComunhao: this.regimeComunhaoSocio.value,
					conjuge: {
						documento: this.conjugeDocumentoSocio.value,
						email: this.conjugeNomeSocio.value,
						nome: this.conjugeNomeSocio.value,
						telefone: this.conjugeTelefoneSocio.value,
					},
				},
			];

			this.sociosParaAtualizar = [
				...this.sociosParaAtualizar,
				{
					administrador: !!this.administradorSocio.value,
					socioPep: !!this.isSocioPep.value,
					dataNascimento: moment(this.dataNascimentoSocio.value, 'DD/MM/YYYY').toDate(),
					documento: this.documentoSocio.value.replace(/\D/g, ''),
					estadoCivil: this.estadoCivilSocio.value,
					regimeComunhao: this.regimeComunhaoSocio.value,
					conjuge: {
						telefone: this.conjugeTelefoneSocio.value ? this.conjugeTelefoneSocio.value.replace(/\D/g, '') : null,
						documento: this.conjugeDocumentoSocio.value ? this.conjugeDocumentoSocio.value.replace(/\D/g, '') : null,
						email: this.conjugeEmailSocio.value,
						nome: this.conjugeNomeSocio.value,
					},
					documentoPessoal: {
						emitidoEm: moment(this.dataEmissaoDocumentoSocio.value, 'DD/MM/YYYY').toDate(),
						numeroRegistro: this.numeroDocumentoSocio.value,
						ufExpedidor: this.ufExpedidorDocumentoSocio.value,
						tipo: this.tipoDocumentoSocio.value,
						orgaoExpedidor: this.orgaoExpedidorDocumentoSocio.value,
						uploadsIds: [],
					},
					email: this.emailSocio.value,
					endereco: {
						bairro: this.bairroSocio.value,
						cep: this.cepSocio.value.replace(/\D/g, ''),
						cidade: this.cidadeSocio.value,
						complemento: this.complementoSocio.value,
						logradouro: this.enderecoSocio.value,
						numero: this.numeroSocio.value,
						uf: this.estadoSocio.value,
					},
					nome: this.nomeSocio.value,
					nomeMae: this.nomeMaeSocio.value,
					percentualSocietario: !this.administradorSocio.value ? parseFloat(this.percentualSocietarioSocio.value) : null,
					telefone: this.celularSocio.value.replace(/\D/g, ''),
				},
			];

			this.step = StepsEnum.ListaEditarDadosSocios;
			this.formSocio.reset();
			this.formSocioEndereco.reset();

			Object.keys(this.formSocio.controls).forEach((field) => {
				const control = this.formSocio.get(field);
				control.markAsPristine({ onlySelf: true });
			});

			this.percentualSocietarioSocio.enable();

			Object.keys(this.formSocioEndereco.controls).forEach((field) => {
				const control = this.formSocioEndereco.get(field);
				control.markAsPristine({ onlySelf: true });
			});

			this.autenticacaoService.refreshAccessTokenJwe().subscribe();
		}
	}

	finalizarEdicaoSocios() {
		// Usuario criou novos sócios e submeteu e será encaminhado para upload dos documentos do sócio
		const totalPercentual = this.sociosParaAtualizar.reduce((total, actual) => total + actual.percentualSocietario, 0);

		if (totalPercentual < 0.1) {
			this.toastService.open('O total da participação societária precisa ser no mínimo 0.1%', ToastTipo.Erro, 5000);
			return;
		}

		this.step = StepsEnum.UploadDocumentosSocios;
	}

	administradorCheck(event: MatCheckboxChange) {
		if (event.checked) {
			this.formSocio.get('percentualSocietario').setValue(null);
			this.formSocio.get('percentualSocietario').disable();
			this.formSocio.get('percentualSocietario').setValidators([]);
		} else {
			this.formSocio.get('percentualSocietario').enable();
			this.formSocio.get('percentualSocietario').setValidators([Validators.required, Validators.min(0.1), Validators.max(100)]);
		}

		this.formSocio.get('percentualSocietario').updateValueAndValidity();
	}

	// Upload Socios

	confirmarUploadSocios() {
		// O usuário confirmou o step de upload, se houver pelo menos um arquivo por sócio ele segue para o próximo step
		const checkFiles = this.sociosParaAtualizar.reduce((check, atual) => {
			let hasFile: boolean;
			if (atual.documentoPessoal.tipo === TipoDocumentoEnum.RG) {
				hasFile = atual.documentoPessoal.uploadsIds.length > 0;
			} else if (atual.documentoPessoal.tipo === TipoDocumentoEnum.CNH) {
				hasFile = atual.documentoPessoal.uploadsIds.length > 0;
			} else if (atual.documentoPessoal.tipo === TipoDocumentoEnum.RNE) {
				hasFile = atual.documentoPessoal.uploadsIds.length > 0;
			}

			if (hasFile) {
				return check;
			} else {
				return false;
			}
		}, true);

		if (checkFiles) {
			// Os arquivos do sócio foram enviados e o usuário será encaminhado para o upload de contrato social, já que houve alteração dos sócios
			this.step = StepsEnum.UploadContratoSocial;
		} else {
			this.toastService.open('Efetue o upload dos documentos dos sócios e administradores', ToastTipo.Erro, 5000);
		}
	}

	uploadSocioChange(socios: SocioParaAlterarModel[]) {
		// Evento quando é feito um novo upload de documento de sócios, nesse passo a lista de sócios é atualizada com os ids de uploads vindos do box-upload
		this.sociosParaAtualizar = socios;
	}

	// Upload Empresa
	selecionarDoc(event) {
		// Usuario clicou para fazer o upload de contrato social

		const inputFile: HTMLElement = event.target.children[0];
		if (inputFile) {
			inputFile.click();
		}
	}

	// Upload de contrato social concluido
	isConcluidoDocumentoEmpresa(index: number) {
		return this.documentoEmpresa[index].loadArquivoConclusao;
	}

	// Houve erro no upload do contrato social concluido
	hasErroDocumentoEmpresa(index: number) {
		return this.documentoEmpresa[index].erro;
	}

	// Usuário optou por remover o arquivo de contrato social
	removerDocumentoEmpresa(index: number) {
		this.documentoEmpresa = this.documentoEmpresa.filter((d, i) => i !== index);
	}

	// Ação do upload de contrato social
	enviarDoc(event) {
		const fileList = event.target.files as FileList;

		if (fileList[0].size > 0 && fileList[0].size < this.maxFileSize) {
			const tipoPermitido = tiposPermitidos.find((tipo) => tipo === fileList[0].type);

			if (tipoPermitido) {
				if (fileList.length > 0) {
					this.documentoEmpresa.push({
						loadArquivo: false,
						loadArquivoConclusao: false,
						erro: false,
						file: null,
						uploadId: null,
					});

					const lastIndex = this.documentoEmpresa.length - 1;

					this.documentoEmpresa[lastIndex].loadArquivo = true;

					Array.from(fileList).map((documento) => {
						this.documentoEmpresa ? (this.documentoEmpresa[lastIndex].file = documento) : [];
					});

					this.atualizacaoCadastralService.uploadArquivo(fileList, this.idAtualizacaoCadastral).subscribe(
						(ret) => {
							// O id do uplod do documento é guardado para envio na finalização
							this.documentoEmpresa[lastIndex].uploadId = ret.uploadId;
							this.documentoEmpresa[lastIndex].loadArquivoConclusao = true;
							this.documentoEmpresa[lastIndex].erro = false;
						},
						(error) => {
							// Houve um erro no upload de arquivo
							this.atualizarDadosSociosFinal.uploadsIds[0] = null;
							this.documentoEmpresa[lastIndex].loadArquivoConclusao = false;
							this.documentoEmpresa[lastIndex].erro = true;
							throw new Error(error);
						}
					);
				}
			} else {
				this.toastService.open('Formato de arquivo não permitido', ToastTipo.Erro, 5000);
			}
		} else {
			const errorMessage = fileList[0].size === 0 ? 'Arquivo não deve ser vazio' : 'Tamanho máximo para arquivos é de 5 Mb';
			this.toastService.open(errorMessage, ToastTipo.Erro, 5000);
		}
	}

	confirmarUpload() {
		// O usuário confirmou o upload de contrato social
		const hasUpload = this.documentoEmpresa.reduce((check, actual) => {
			if (actual.uploadId) {
				return check;
			} else {
				return false;
			}
		}, true);

		if (this.documentoEmpresa.length > 0 && hasUpload) {
			this.atualizarDadosSociosFinal.uploadsIds = this.documentoEmpresa.map((d) => d.uploadId);

			// Se não houve alteração de sócios não é necessário enviar os dados
			if (this.listaDeSociosFoiAlterada) {
				this.atualizarDadosSociosFinal.socios = this.sociosParaAtualizar;
			}

			// Utiliza o id de atualização recebido no step de atualização de empresa
			this.atualizarDadosSociosFinal.atualizacaoId = this.idAtualizacaoCadastral;

			// Confirma a atualização final e vai para confirmação da atualização cadastral
			this.loader = true;
			this.atualizacaoCadastralService
				.updateSocios(this.atualizarDadosSociosFinal)
				.pipe(finalize(() => (this.loader = false)))
				.subscribe(() => {
					this.step = StepsEnum.ConfirmacaoDados;
				});
		} else {
			this.toastService.open('Efetue o upload do contrato social para continuar.', ToastTipo.Erro, 5000);
		}
	}

	confirmacaoCadastroChange(event: MatCheckboxChange) {
		this.confirmacaoAtualizacao = event.checked;
	}

	confirmarAtualizacao() {
		if (this.confirmacaoAtualizacao) {
			this.loader = true;
			this.atualizacaoCadastralService
				.confirmar(this.idAtualizacaoCadastral)
				.pipe(finalize(() => (this.loader = false)))
				.subscribe(() => {
					this.step = StepsEnum.Sucesso;
				});
		} else {
			this.toastService.open('Confirme a declaração dos dados informados para prosseguir', ToastTipo.Erro, 5000);
		}
	}

	close() {
		this.modalService.close();
	}

	tipoSocio(tipo: TipoSocioEnum): string {
		return {
			[TipoSocioEnum.Acionista]: 'Acionista',
			[TipoSocioEnum.Socio]: 'Sócio',
			[TipoSocioEnum.Procurador]: 'Procurador',
			[TipoSocioEnum.RepresentanteLegal]: 'Representante Legal',
			[TipoSocioEnum.Responsavel]: 'Responsavel',
			[TipoSocioEnum.BeneficiarioFinal]: 'Beneficiario Final',
		}[tipo];
	}

	get listaDeSocios(): Array<any> {
		return this.dadosEmpresa.socios;
	}

	get totalParticipacao(): number {
		return parseFloat(this.dataSourceSocios.data.reduce((total, actual) => total + actual.percentualSocietario, 0).toFixed(2));
	}

	get StepsEnum() {
		return StepsEnum;
	}

	get TipoDocumentoEnum() {
		return TipoDocumentoEnum;
	}

	get EstadoCivilEnum() {
		return EstadoCivilEnum;
	}

	get RegimeComunhaoEnum() {
		return RegimeComunhaoEnum;
	}

	get cnpj(): AbstractControl {
		return this.formEmpresa.get('cnpj');
	}
	get email(): AbstractControl {
		return this.formEmpresa.get('email');
	}
	get telefone(): AbstractControl {
		return this.formEmpresa.get('telefone');
	}
	get razaoSocial(): AbstractControl {
		return this.formEmpresa.get('razaoSocial');
	}
	get nomeFantasia(): AbstractControl {
		return this.formEmpresa.get('nomeFantasia');
	}
	get dataConstituicao(): AbstractControl {
		return this.formEmpresa.get('dataConstituicao');
	}
	get naturezaJuridica(): AbstractControl {
		return this.formEmpresa.get('naturezaJuridica');
	}
	get faturamentoAnual(): AbstractControl {
		return this.formEmpresa.get('faturamentoAnual');
	}

	get endereco(): AbstractControl {
		return this.formEmpresaEndereco.get('endereco');
	}
	get bairro(): AbstractControl {
		return this.formEmpresaEndereco.get('bairro');
	}
	get cep(): AbstractControl {
		return this.formEmpresaEndereco.get('cep');
	}
	get numero(): AbstractControl {
		return this.formEmpresaEndereco.get('numero');
	}
	get complemento(): AbstractControl {
		return this.formEmpresaEndereco.get('complemento');
	}
	get cidade(): AbstractControl {
		return this.formEmpresaEndereco.get('cidade');
	}
	get estado(): AbstractControl {
		return this.formEmpresaEndereco.get('estado');
	}

	get documentoSocio(): AbstractControl {
		return this.formSocio.get('documento');
	}
	get nomeSocio(): AbstractControl {
		return this.formSocio.get('nome');
	}
	get emailSocio(): AbstractControl {
		return this.formSocio.get('email');
	}
	get dataNascimentoSocio(): AbstractControl {
		return this.formSocio.get('dataNascimento');
	}
	get celularSocio(): AbstractControl {
		return this.formSocio.get('celular');
	}
	get nomeMaeSocio(): AbstractControl {
		return this.formSocio.get('nomeMae');
	}
	get percentualSocietarioSocio(): AbstractControl {
		return this.formSocio.get('percentualSocietario');
	}
	get administradorSocio(): AbstractControl {
		return this.formSocio.get('administrador');
	}
	get isSocioPep(): AbstractControl {
		return this.formSocio.get('socioPep');
	}
	get tipoDocumentoSocio(): AbstractControl {
		return this.formSocio.get('tipoDocumento');
	}
	get numeroDocumentoSocio(): AbstractControl {
		return this.formSocio.get('numeroDocumento');
	}
	get dataEmissaoDocumentoSocio(): AbstractControl {
		return this.formSocio.get('dataEmissaoDocumento');
	}
	get ufExpedidorDocumentoSocio(): AbstractControl {
		return this.formSocio.get('ufExpedidorDocumento');
	}
	get orgaoExpedidorDocumentoSocio(): AbstractControl {
		return this.formSocio.get('orgaoExpedidorDocumento');
	}
	get estadoCivilSocio(): AbstractControl {
		return this.formSocio.get('estadoCivil');
	}
	get regimeComunhaoSocio(): AbstractControl {
		return this.formSocio.get('regimeComunhao');
	}
	get conjugeNomeSocio(): AbstractControl {
		return this.formSocio.get('conjugeNome');
	}
	get conjugeDocumentoSocio(): AbstractControl {
		return this.formSocio.get('conjugeDocumento');
	}
	get conjugeEmailSocio(): AbstractControl {
		return this.formSocio.get('conjugeEmail');
	}
	get conjugeTelefoneSocio(): AbstractControl {
		return this.formSocio.get('conjugeTelefone');
	}

	get enderecoSocio(): AbstractControl {
		return this.formSocioEndereco.get('endereco');
	}
	get bairroSocio(): AbstractControl {
		return this.formSocioEndereco.get('bairro');
	}
	get cepSocio(): AbstractControl {
		return this.formSocioEndereco.get('cep');
	}
	get numeroSocio(): AbstractControl {
		return this.formSocioEndereco.get('numero');
	}
	get complementoSocio(): AbstractControl {
		return this.formSocioEndereco.get('complemento');
	}
	get cidadeSocio(): AbstractControl {
		return this.formSocioEndereco.get('cidade');
	}
	get estadoSocio(): AbstractControl {
		return this.formSocioEndereco.get('estado');
	}

	get getDiasRestantes() {
		return this.diasRestantes.toString().padStart(2, "0");
	}
}
