import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutenticacaoGuard, LoggedInGuard, PartnerResolver } from '@bs2/utilities-v9';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
		canActivate: [AutenticacaoGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'autenticacao',
		loadChildren: () => import('./modules/autenticacao/autenticacao.module').then((m) => m.AutenticacaoModule),
		canActivate: [LoggedInGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'recuperar-senha',
		loadChildren: () => import('./modules/recuperar-senha/recuperar-senha.module').then((m) => m.RecuperarSenhaModule),
		canActivate: [LoggedInGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'bs2pay',
		loadChildren: () => import('./modules/bs2-pay/bs2-pay.module').then((m) => m.Bs2PayModule),
		canActivate: [AutenticacaoGuard],
		resolve: { partner: PartnerResolver },
	},

	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor() {
		document
			.querySelector('meta[name="viewport"]')
			.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
	}
}
